import React, { useState, useEffect } from "react";
import { graphql, Link } from "gatsby";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import BEMHelper from "react-bem-helper";
import SwiftType from "../components/swift-type";

import Img from "gatsby-image/withIEPolyfill";
// Components
import Layout from "../components/layout";
import SEO from "../components/seo";
import HeaderImageVideo from "../modules/headers/header-image-video";
import HeaderSolidColor from "../modules/headers/header-solid-color";
import ItemKeyStat from "../module-items/item-key-stat";
import ItemMediaCard from "../module-items/item-media-card";
import HubspotForm from "../components/hubsport-form";
import MobileMenuArrow from "../blocks/mobile-menu-arrow";

// Helpers, Functions
import { richTextConfig } from "../rich-text-config/rich-text-config";

import clientGlobalPagePaths from "../../global-page-paths.json";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const bem = new BEMHelper({
  name: "template-insight",
});

const TemplateInsight = ({ data, location, pageContext }) => {
  const {
    publishDate,
    insightType,
    insightTitle,
    seoPageTitle,
    headerIntro,
    mainImage,
    relatedSolution,
    includeVantageInRelatedSolutions,
    addKeyResults,
    addVideo,
    addForm,
    attachFileDownload,
    previewExcerpt,
    swiftDate,
  } = data.contentfulTemplateInsight;

  useEffect(() => {
    const gtmData = data.contentfulTemplateInsight;
    const obj = { url : gtmData.slug, pageType: "content", pageTitle: gtmData.insightTitle }
    if(gtmData.insightType.insightType.toLowerCase() === "article") {
      obj.contentType = gtmData.insightType.insightType.toLowerCase()
      obj.sbu= "CCS"
      obj.contentTags = ["insight", "solution"]
      obj.contentLength = 5260
      obj.contentPublicationDate = "2022-03-18T08:06:38Z"
      obj.timestamp = "2022-03-18T15:23:44"
      obj.form= "no"
    } else {
      obj.contentType = gtmData.insightType.insightType.replace(" ", "-").toLowerCase()
      obj.sbu= "LIT"
      obj.contentTags = ["insight", "solution"]
      obj.contentLength = 411
      obj.contentPublicationDate = "2022-03-18T08:06:38Z"
      obj.timestamp = "2022-03-18T15:23:44"
      obj.form= "yes"
      obj.formId= gtmData.addForm?.formId
    }
    window?.dataLayer?.push(obj);
  },[])

  // console.log('yeeet', data.contentfulTemplateInsight)

  const { whitepaperBack } = data.whitepaperBackgroundPattern;
  const showVantageLink = includeVantageInRelatedSolutions === true ? true : false;

  let solutionTitle = null;
  let solutionSlug = null;
  let solutionImg = null;

  if (relatedSolution) {
    if (relatedSolution?.pageTitle) {
      solutionTitle = relatedSolution?.pageTitle;
    }
    if (relatedSolution?.slug) {
      solutionSlug = `/solutions/${relatedSolution?.slug}`;
    }

    //   if (
    //     relatedSolution?.header &&
    //     Object.keys(relatedSolution?.header).length > 0
    //   ) {
    //     if (relatedSolution?.header.headerImage.fixed) {
    //       solutionImg = relatedSolution?.header.headerImage.fixed;
    //     }
    //     console.log("lets see header ", relatedSolution?.header);
    //   }
  }

  const insightSolution = () => {
    if (relatedSolution?.pageTitle.includes("Contracting")) {
      return "Contracting";
    } else if (relatedSolution?.pageTitle.includes("Operations")) {
      return "Operations, Risk and Compliance";
    } else if (relatedSolution?.pageTitle.includes("Litigation")) {
      return "Litigation and Investigations";
    } else return relatedSolution?.pageTitle;
  };

  useEffect(() => {
    // document.getElementById("loader_wrap").classList.add("d-none");
    // document.getElementById("loader_wrap").classList.add("loaded");
  }, []);
  const bodyJson = data?.contentfulTemplateInsight?.body ? JSON.parse(data?.contentfulTemplateInsight?.body?.raw) : null;
  const swiftBody =
  bodyJson ? bodyJson.content[0].content[0]?.value: null;

  const authors = data.contentfulTemplateInsight.addSpeakersOrAuthors
    ? data.contentfulTemplateInsight.addSpeakersOrAuthors
    : null;

  const metaTitle = seoPageTitle || `${insightTitle} | UnitedLex`;
  return (
    <Layout
      isWhiteNav={insightType?.insightType === "White Paper" ? true : false}
      location={location}
    >
      <SEO
        title={metaTitle}
        description={previewExcerpt?.previewExcerpt}
        image={mainImage?.file?.url}
        url={location?.href}
      />
      <SwiftType
        contentTypes={[
          insightType?.insightType,
          "insight",
          "solution",
          insightSolution()
        ]}
        solutions={[insightSolution()]}
        customFeatImg={mainImage?.file?.url}
        customExcerpt={previewExcerpt?.previewExcerpt}
        customTitle={insightTitle}
        customDesc={previewExcerpt?.previewExcerpt || swiftBody}
        customPublishDate={new Date(swiftDate).getTime() / 1000}
        tags={["insight", "solution"]}
      />

      {insightType?.insightType === "White Paper" ? (
        <div {...bem("whitepaper")}>
          <div {...bem("whitepaper-body")}>
            {/*<Img*/}
            {/*  {...bem("whitepaper-pattern")}*/}
            {/*  fluid={data.whitepaperBackgroundPattern.childImageSharp.fluid}*/}
            {/*  style={{ position: "absolute" }}*/}
            {/*  alt={data.whitepaperBackgroundPattern.childImageSharp.description || ""}*/}
            {/*/>*/}
            <GatsbyImage
              {...bem("whitepaper-pattern")}
              style={{ position: "absolute" }}
              alt={data.whitepaperBackgroundPattern.childImageSharp.description || ""}
              title={data.whitepaperBackgroundPattern.childImageSharp.description || ""}
              image={getImage(data.whitepaperBackgroundPattern.childImageSharp)}/>

            <div {...bem("whitepaper-copy")}>
              <h1>{data.contentfulTemplateInsight.insightTitle}</h1>
              {data.contentfulTemplateInsight.body &&
              documentToReactComponents(
                JSON.parse(data.contentfulTemplateInsight.body.raw),
                richTextConfig()
              )}
            </div>
          </div>

          <div {...bem("whitepaper-form")}>
            {addForm && (
              <React.Fragment>
                <HubspotForm
                  // ref={formRef}
                  portalId={process.env.GATSBY_HUBSPOT_PORTAL_ID}
                  // formId="d2a3ad47-8029-4b9c-a8a5-4f1dacd271b9"
                  formId={addForm.formId}
                  // formId="7fd2f409-cc05-4ca7-8efb-c28c1a320fde"
                  // formId="95a42221-4715-4685-ad85-5ad2dbf7164e"
                  onSubmit={() => console.log("Submit!")}
                  onReady={(form) => {
                    console.log("Form ready: ", form);
                  }}
                  loading={<div>Loading...</div>}
                  invertTextColor={true}
                  formHeader={
                    addForm.formHeader ? addForm.formHeader : null
                  }
                  successMessage={
                    addForm.successMessage ? addForm.successMessage : null
                  }
                  gatedDownloadFile={
                    attachFileDownload ? attachFileDownload.file.url : null
                  }
                />
              </React.Fragment>
            )}
          </div>
        </div>
      ) : (
        <React.Fragment>
          {mainImage && insightType?.insightType !== "Video" ? (
            <HeaderImageVideo
              isInsightsPage={true}
              eyebrowText={insightType ? insightType?.insightType : null}
              headerTitle={insightTitle || null}
              text={headerIntro ? headerIntro.headerIntro : null}
              headerImage={mainImage ? mainImage : null}
            />
          ) : (
            <HeaderSolidColor
              backgroundColor={"purple-dark"}
              headerTitle={insightTitle || null}
              headerDescription={headerIntro ? headerIntro.headerIntro : null}
              eyebrowText={insightType ? insightType?.insightType : null}
            />
          )}
          <div {...bem()}>
            {/* {addVideo && (
          <div {...bem("video")}>
            <div {...bem("video-container")}>
              <ItemMediaCard {...addVideo} />
            </div>
            <div {...bem("speaker-container")}>
              <h2>Speakers</h2>
            </div>
          </div>
        )} */}
            {addKeyResults && (
              <React.Fragment>
                <div
                  {...bem("key-results-wrap", {
                    [`has-${addKeyResults.length}`]: addKeyResults
                  })}
                >
                  {addKeyResults.map((stat, i) => {
                    if (i === 0) {
                      return (
                        <ItemKeyStat
                          bigText={stat.bigText ? stat.bigText : null}
                          littleText={
                            stat.littleText ? stat.littleText.littleText : null
                          }
                        />
                      );
                    } else {
                      return (
                        <ItemKeyStat
                          bigText={stat.bigText ? stat.bigText : null}
                          littleText={
                            stat.littleText ? stat.littleText.littleText : null
                          }
                        />
                      );
                    }
                  })}
                </div>
              </React.Fragment>
            )}
            <div {...bem("body")}>
              <article {...bem("insight-content")}>
                {addVideo && (
                  <div {...bem("insight-video-container")}>
                    <ItemMediaCard {...addVideo} />
                  </div>
                )}
                {/* {data.contentfulTemplateInsight.insightTitle && (
              <h1>{data.contentfulTemplateInsight.insightTitle}</h1>
            )}
            <p>{data.contentfulTemplateInsight.publishDate}</p>
            <p>{`Insight Type: ${data.contentfulTemplateInsight.insightType.insightType}`}</p> */}

                <div {...bem("body-copy-wrapper")}>
                  {data.contentfulTemplateInsight.insightType &&
                    data.contentfulTemplateInsight.insightType.insightType ===
                      "Newsroom" &&
                    data.contentfulTemplateInsight.publishDate && (
                      <div {...bem("date")}>
                        {data.contentfulTemplateInsight.publishDate}
                      </div>
                    )}
                  {renderRichText(data.contentfulTemplateInsight.body,richTextConfig())}
                </div>
              </article>
              <aside {...bem("aside")}>
                {authors && (
                  <div {...bem("aside-section")}>
                    <h4 {...bem("aside-section-header")}>
                      {addVideo ? "Speaker" : "Authors"}
                    </h4>
                    {authors.map((author, i) => (
                      <React.Fragment>
                        {author.slug ? (
                          <Link
                            {...bem("aside-section-content", "author")}
                            to={
                              clientGlobalPagePaths[`${author.contentful_id}`]
                            }
                          >
                            <div {...bem("author")}>
                              <h4>{author.name}</h4>
                              {author.__typename ===
                              "ContentfulGlobalPeople" ? (
                                <p {...bem("author-longtext")}>
                                  {author.jobTitle.jobTitle}
                                </p>
                              ) : (
                                documentToReactComponents(
                                  JSON.parse(author.jobTitle.raw),
                                  richTextConfig()
                                )
                              )}
                              {/* {author.slug && (
                          <Link
                            to={
                              clientGlobalPagePaths[`${author.contentful_id}`]
                            }
                          >
                            View
                          </Link>
                        )} */}

                              {/* {author.image && (
                        <Img
                          {...bem("teaser-image", "author")}
                          fluid={author.image.fluid}
                        />
                      )} */}
                            </div>
                          </Link>
                        ) : (
                          <div {...bem("aside-section-content", "author")}>
                            <div {...bem("author")}>
                              <h4>{author.name}</h4>
                              {author.__typename ===
                              "ContentfulGlobalPeople" ? (
                                <p {...bem("author-longtext")}>
                                  {author.jobTitle.jobTitle}
                                </p>
                              ) : (
                                documentToReactComponents(
                                  JSON.parse(author.jobTitle.raw),
                                  richTextConfig()
                                )
                              )}
                              {/* {author.slug && (
                          <Link
                            to={
                              clientGlobalPagePaths[`${author.contentful_id}`]
                            }
                          >
                            View
                          </Link>
                        )} */}
                            </div>
                            {/* {author.image && (
                        <Img
                          {...bem("teaser-image", "author")}
                          fluid={author.image.fluid}
                        />
                      )} */}
                          </div>
                        )}
                      </React.Fragment>
                    ))}
                  </div>
                )}
                {solutionTitle && solutionSlug ? (
                  <div {...bem("aside-section")}>
                    <h4 {...bem("aside-section-header")}>Related Solutions</h4>
                    <div {...bem("aside-section-content", "related-solution")}>
                      <div>
                        <h4>{solutionTitle}</h4>
                        <Link to={solutionSlug}>
                          View
                          <MobileMenuArrow />
                        </Link>
                      </div>
                      {solutionImg && (
                        <GatsbyImage {...bem("teaser-image")} image={getImage(solutionImg.gatsbyImageData)} alt={solutionImg.description || ""} title={solutionImg.description || ""} />
                      )}
                    </div>
                  </div>
                ) : null}

                {showVantageLink && (
                  <div {...bem("aside-section")}>
                    <div {...bem("aside-section-content", "related-product")}>
                      <div>
                        <h4>Vantage</h4>
                        <p>UnitedLex Vantage is the leading Enterprise Legal Platform (ELP), built on Microsoft to
                          deliver end-to-end integration between the business and law department.</p>
                        <Link to="/products">
                          Learn More
                          <MobileMenuArrow />
                        </Link>
                      </div>
                    </div>
                  </div>
                )}
              </aside>
            </div>
          </div>
        </React.Fragment>
      )}
    </Layout>
  );
};

export default TemplateInsight;

export const pageQuery = graphql`
  query($slug: String!) {
    whitepaperBackgroundPattern: file(
      relativePath: { eq: "img/patterns/whitepaper-pattern.png" }
    ) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        gatsbyImageData
        # fluid {
        # ...GatsbyImageSharpFluid
        # }
      }
    }
    contentfulTemplateInsight(slug: { eq: $slug }) {
      previewExcerpt {
        previewExcerpt
      }
      insightTitle
      seoPageTitle
      slug
      doNotIndex
      headerIntro {
        headerIntro
      }
      mainImage {
        description
        gatsbyImageData
        # fluid(maxWidth: 1000, toFormat: JPG, quality: 80) {
        #   ...GatsbyContentfulFluid
        # }
        file {
          url
        }
      }
      publishDate(formatString: "MMMM D, YYYY")
      swiftDate: publishDate(formatString: "YYYY.MM.DD")
      insightType {
        insightType
      }
      relatedSolution {
        slug
        pageTitle
        header {
          ... on Node {
            ... on ContentfulModuleHeaderWithMedia {
              __typename
              headerImage {
                description
                gatsbyImageData
                # fixed(
                # toFormat: JPG
                # width: 120
                # height: 200
                # cropFocus: CENTER
                # resizingBehavior: FILL
                # ) {
                #  ...GatsbyContentfulFixed
                # }
              }
            }
          }
        }
      }
      includeVantageInRelatedSolutions
      body {
        raw
        references {
            ... on ContentfulAsset {
              contentful_id
              __typename
              title
              description
              gatsbyImageData
            }
            ... on ContentfulTemplateInsight {
              contentful_id
              slug
            }
            ... on ContentfulTemplateService {
              slug
              contentful_id
            }
            ... on ContentfulTemplateSolution {
              slug
              contentful_id
            }
        }
      }
      addKeyResults {
        bigText
        littleText {
          littleText
        }
      }
      addSpeakersOrAuthors {
        ... on Node {
          ... on ContentfulGlobalPeople {
            __typename
            contentful_id
            name
            jobTitle {
              jobTitle
            }
            image {
              gatsbyImageData
              # fluid(toFormat: JPG, maxWidth: 300) {
              #   ...GatsbyContentfulFluid
              # }
            }
          }
          ... on Node {
            ... on ContentfulTemplateLeadership {
              __typename
              contentful_id
              name: fullName
              slug
              jobTitle {
                raw
              }
              image: picture {
                gatsbyImageData
                # fluid(toFormat: JPG, maxWidth: 300) {
                #   ...GatsbyContentfulFluid
                # }
              }
            }
          }
        }
      }
      addVideo {
        ...ContentfulItemMediaCardFragment
      }
      addForm {
        contentful_id
        formId
        formHeader {
          raw
        }
        successMessage {
          raw
        }
      }
      attachFileDownload {
        file {
          url
        }
      }
    }
  }
`;
